const rutas = {
    home: {
        id: 'menu.principal',
        label: 'PRINCIPAL',
        subtitle: 'menu.subtitle.principal',
        href: '/',
    },
    comunidad: {
        id: 'menu.comunidad',
        label: 'COMUNIDAD',
        subtitle: 'menu.subtitle.comunidad',
        href: '/comunidad',
    },
    voluntarios: {
        id: 'menu.voluntarios',
        label: 'VOLUNTARIOS',
        subtitle: 'menu.subtitle.voluntarios',
        href: '/voluntarios',
    },
    retiros: {
        id: 'menu.retiros',
        label: 'RETIROS',
        subtitle: 'menu.subtitle.retiros',
        href: '/retiros',
    },
    apartamentos: {
        id: 'menu.hospedaje',
        label: 'HOSPEDAJE',
        subtitle: 'menu.subtitle.hospedaje',
        href: '/hospedaje',
    },
    galeria: {
        id: 'menu.galeria',
        label: 'GALERIA',
        subtitle: 'menu.subtitle.galeria',
        href: '/galeria',
    },
    contacto: {
        id: 'menu.contacto',
        label: 'CONTACTO',
        subtitle: 'menu.subtitle.contacto',
        href: '/contacto',
    },
    contactanos: {
        id: 'menu.contactanos',
        label: 'Contáctanos',
        subtitle: 'menu.subtitle.contactanos',
        href: '/contacto',
    },

}

const seccionHome = {
    seccion1: 'seccion1',
    seccion2: 'seccion2',
    seccion3: 'seccion3',
    seccion4: 'seccion4',
    seccion5: 'seccion5',
    seccion6: 'seccion6',
    seccion7: 'seccion7',
}


const menu = {
    children: [
        {
            name: 'itemApartamentos',
            children: {
                href: rutas.apartamentos.href,
                name: rutas.apartamentos.id
            },
        },
        {
            name: 'itemRetiros',
            children: {
                href: rutas.retiros.href,
                name: rutas.retiros.id
            },
        },
        {
            name: 'itemComunidad',
            children: {
                href: rutas.comunidad.href,
                name: rutas.comunidad.id
            },
        },
        {
            name: 'itemVoluntarios',
            children: {
                href: rutas.voluntarios.href,
                name: rutas.voluntarios.id
            },
        },
        {
            name: 'itemGaleria',
            children: {
                href: rutas.galeria.href,
                name: rutas.galeria.id
            },
        },
        {
            name: 'item~kzxn246esnh',
            children: {
                href: rutas.contactanos.href,
                href: rutas.contactanos.id,
            },
        },
        /*
        {
            name: 'item~serv',
            children: {
                href: '#',
                name: "Ejemplos",
                submenu: [
                    {
                        href: '/index-2',
                        name: "index-2"
                    },
                    {
                        href: '/index-3',
                        name: "index-3"
                    },
                    {
                        href: '/index-4',
                        name: "index-4"
                    },
                    {
                        href: '/onepage',
                        name: "onepage"
                    },
                    {
                        href: '/rtl-home',
                        name: "rtl-home"
                    },
                    {
                        href: '/404',
                        name: "404"
                    },
                    {
                        href: '/blog-details',
                        name: "blog-details"
                    },
                    {
                        href: '/blog-standard',
                        name: "blog-standard"
                    },
                    {
                        href: '/careers',
                        name: "careers"
                    },
                    {
                        href: '/checkout',
                        name: "checkout"
                    },
                    {
                        href: '/faq',
                        name: "faq"
                    },
                    {
                        href: '/portfolio-2',
                        name: "portfolio-2"
                    },
                    {
                        href: '/portfolio-details',
                        name: "portfolio-details"
                    },
                    {
                        href: '/product-details',
                        name: "product-details"
                    },
                    {
                        href: '/services-2',
                        name: "services-2"
                    },
                    {
                        href: '/services-details',
                        name: "services-details"
                    },
                    {
                        href: '/shop',
                        name: "shop"
                    },
                    {
                        href: '/shopping-cart',
                        name: "shopping-cart"
                    },
                    {
                        href: '/team-details',
                        name: "team-details"
                    },
                    {
                        href: '/test',
                        name: "test"
                    },
                ]
            },
        },*/
    ],
};


export { rutas, seccionHome, menu, };