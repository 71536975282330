import React, { Component } from 'react';

const headerDS = {
  hospedaje: 'Conscious Lodging',
  hospedajeDetalle: 'We relate in a pure state with our environment, adapting nature, culture, space, gastronomy to a restful mindfulness of our guests.',
  evento: 'Events, conventions and retreats',
  eventoDetalle: 'We open the doors of our home to social events, business trainings or wellness retreats.',
  vinculacion: 'Link with the community',
  vinculacionDetalle: 'Our commitment is with the community of Quillosisa and surrounding areas of Santa Isabel, Azuay',

};

const abautDS = {
  title: "30 000 mts ² of regenerative and sustainable agriculture",
  subtitle: 'Who are we?',
  detail: "Inti Kamari (gift of the sun in Kichwa) is a family project that was born in 2016 with the need to provide a space for detachment, relaxation and comfort outside the limits of everyday city life.",
  detail2: "We base ourselves on the principles of nature hosting, conscious eating, ecotourism and permaculture, so that our guests reconnect with their origins: mother earth.",
  detail3: "More than two thousand people have visited us in the last three years, in some one hundred and twenty meetings and retreats. Our foundation has offered a hundred free workshops, excursions and medical brigades to the community."
};

const statisticsDS = {
  title: 'Grow, Transform, Heal',
  subtitle: 'Inti Kamari',
  statistics1: 'people',
  statisticsDetail1: 'They stayed at our home!',
  statistics2: 'withdrawals',
  statisticsDetail2: 'Done in 6 years',
  statistics3: 'projects',
  statisticsDetail3: 'And more, made in favor of our community',
};

const mapsDS = {
  title: 'Location',
  subtitle: 'Inti Kamari',
  detail: 'We are in the beautiful sub tropical valley of Yunguilla, in the Andes mountain range. A great mountainous landscape and nature trails to walk. A pleasant climate the whole year. And just one hour from Cuenca, the third city of Ecuador, and whose center is a cultural heritage of humanity',
}

const natureServiceDS = {
  title: 'Services',
  subtitle: 'Nature & Services',
  instalation: 'Facilities',
  instalationDetail: 'They are suitable for a complete rest, immersed in the nature of the Yunguilla Valley. Each space has its rustic touch, perfect to feel isolated from the city environment and fully immerse yourself in the silence of Inti Kamari.',
  activities: 'Activities',
  activitiesDetail: 'Each of them are aimed at promoting your personal development and love for our land. From yoga, tours of our farm, agrotourism tours, horseback riding, walks to tourist areas near Inti Kamari or swimming in our little lake.',
  volunteers: 'Volunteers',
  volunteersDetail: 'Because we have also been travelers and we know about that search for a place to rest, meditate and learn. At Inti Kamari we always require extra hands for all household tasks and we open our doors all year round. Dare to write to us.',
}


const serviceDS = {
  subtitle: 'Inti Kamari',
  salon: 'Salons',
  salonDetail: "At different points within Inti Kamari, we have rooms beautifully embraced by the greenery of the area's native fauna and flora. Ideal for yoga, meditation, events or just to take a breath of fresh air.",
  room: 'Rooms',
  roomDetail: 'Imagine waking up surrounded by organic orchards or fruit trees. An indescribable experience that our rooms offer you. They all have the necessary elements to guarantee that moment of relaxation and sleep.',
  library: 'Library',
  libraryDetail: 'Book lovers? We have an extensive library that covers different literary genres, ideal for reading at any time of the day. Choose your favorite volume and travel through a sensory experience that only a good book can give you.',
  greenAreas: 'Green Areas',
  greenAreasDetail: 'There are 30,000 square meters of regenerative and sustainable agriculture that surround Inti Kamari. These green areas favor the physical and emotional well-being of our guests, since our entire home is surrounded by unique greenery. They are commonly used for physical activities, detachment or for a quiet walk',
  food: 'Food',
  foodDetail: "We base our food within the “conscious” branch, that is, we use organic ingredients, extracted from our garden, which allow delicious and healthy food. We have a vegetarian, vegan, regular menu with meat, chicken or fish and children's.",

  activity: 'Activities',
  activityDetail1: 'We offer, in addition to our lodging, a series of activities that go from:',
  activityDetail2: '- Morning Yoga',
  activityDetail3: '- Agrotourism Tours through our Laboratory of Mushrooms and Fungi',
  activityDetail4: '- Groceries',
  activityDetail5: '- Walks to nearby tourist areas',
  activityDetail6: '- Cavalcade',
  activityDetail7: '- Tours of our farm',
  activityDetail8: 'Or simply what you want to do, with respect and care for each of our spaces that we care for with great affection.',

}

const additionalServices = {
  title: 'Additional Services',
  pet: 'Pet Friendly',
  petDetail1: "At Inti Kamari, we love and respect every creature that visits us. Given this, if you come with your furry friend, remember not to be dominant, to be polite, not to sleep on the beds, clean their waste, not jump into the pool and bring your respective leash or harness.",
  petDetail2: "All this because we share the space with several species, among them are: geese, squirrels, many little birds and cats.",

  food: 'Conscious Food',
  foodDetail1: 'We have a restaurant with delicious food, made with traditional recipes, based on ingredients extracted from our organic garden.',
  foodDetail2: 'This allows us to maintain our conscious eating philosophy which is based on improving the way we eat and how this can benefit the health of each guest.',
  foodDetail3: 'We invite you to enjoy it!',
}

export const home = {
  header: headerDS,
  abaut: abautDS,
  statistics: statisticsDS,
  maps: mapsDS,
  natureService: natureServiceDS,
  service: serviceDS,
  additionalServices: additionalServices,
  footer: "More than six thousand people have visited us in the last 6 years, in more than two hundred meetings and retreats. Our foundation has offered a hundred workshops, excursions and medical brigades in favor of the community of Quillosisa, Santa Isabel.",
}
