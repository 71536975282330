import { comunidad } from "./comunidad";
import { home } from "./home";
import { roomDS } from "./room";
import { retiro } from "./retiro";
import { voluntario } from "./voluntario";
import { menu } from "./menu";

const contactDS = {
  phone: "Phone",
  mail: "Mail",
  location: "Location",
}

const galeryDS = {
  title: 'Gallery',
  all: 'All',
  act: "Activities",
  inst: "Installations",
  nat: "Nature",
}

export const TRANSLATIONS_EN = {
  language: 'Language',
  english: 'English',
  spain: 'Spanish',
  detail: 'Detail',
  more: 'More',
  reserve: 'Reserve',
  home: home,
  comunidad: comunidad,
  retiro: retiro,
  contact: contactDS,
  room: roomDS,
  voluntario: voluntario,
  galery: galeryDS,
  menu: menu
};
