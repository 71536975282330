import React, { Component } from 'react';

const headerDS = {
  hospedaje: 'Hospedaje Consciente',
  hospedajeDetalle: 'Nos relacionamos en estado puro con nuestro entorno, adaptando la naturaleza, cultura, espacio, gastronomía a un descanso mindfulness de nuestros huéspedes.',
  evento: 'Eventos, convenciones y retiros',
  eventoDetalle: 'Abrimos las puertas de nuestro hogar a eventos sociales, capacitaciones empresariales o retiros de bienestar.',
  vinculacion: 'Vinculación con la comunidad',
  vinculacionDetalle: 'Nuestro compromiso está con la comunidad de Quillosisa y zonas aledañas de Santa Isabel, Azuay.',

};

const abautDS = {
  title: "30 000 mts ² de agricultura regenerativa y sustentable",
  subtitle: '¿Quienes Somos?',
  detail: "Inti Kamari (regalo del sol en kichwa) es un proyecto familiar que nace, en 2016, con la necesidad de brindar un espacio de desprendimiento, relajación y comodidad fuera de los límites de la cotidianidad citadina.",
  detail2: "Nos basamos en los principios del hospedaje de naturaleza, alimentación consciente, ecoturismo y permacultura, para que nuestros huéspedes se reconecten con sus orígenes: la madre tierra.",
  detail3: "Más de dos mil personas nos han visitado en los últimos tres años, en unos ciento veinte encuentros y retiros. Nuestra fundación ha ofrecido un centenar de talleres, excursiones y brigadas médicas gratuitas a la comunidad."
};

const statisticsDS = {
  title: 'Crece, Transformate, Sana',
  subtitle: 'Inti Kamari',
  statistics1: 'personas',
  statisticsDetail1: '¡Se hospedaron en nuestro hogar!',
  statistics2: 'retiros',
  statisticsDetail2: 'Efectuados en 6 años',
  statistics3: 'proyectos',
  statisticsDetail3: 'Y más, realizados a favor de nuestra comunidad',
};

const mapsDS = {
  title: 'Ubicación',
  subtitle: 'Inti Kamari',
  detail: ' Nos encontramos en el hermoso valle sub tropical de Yunguilla, en la cordillera de los Andes.  Un gran  paisaje montañoso y senderos naturales para caminar. Un clima agradable el año entero.  Y a sólo una hora de Cuenca,  la tercera ciudad del Ecuador, y cuyo centro es patrimonio cultural de la humanidad.',
}

const natureServiceDS = {
  title: 'Servicios',
  subtitle: 'Naturaleza & Servicios',
  instalation: 'Instalaciones',
  instalationDetail: 'Se encuentran adecuadas para un descanso completo, inmersas en la naturaleza del Valle de Yunguilla. Cada espacio tiene su toque rústico, perfecto para sentirte aislado del ambiente citadino y sumergirte a plenitud en el silencio de Inti Kamari.',

  activities: 'Actividades',
  activitiesDetail: 'Cada una de ellas se encuentran orientadas a fomentar tu desarrollo personal y amor a nuestra tierra. Desde yoga, recorridos por nuestra finca, tours de agroturismo, cabalgata, caminatas a zonas turísticas aledañas a Inti Kamari o nadar en nuestra laguna natural.',
  volunteers: 'Voluntarios',
  volunteersDetail: 'Porque también hemos sido viajeros y sabemos de esa búsqueda por un lugar para descansar, meditar y aprender. En Inti Kamari siempre requerimos de manos extra para todas las tareas del hogar y abrimos nuestras puertas a voluntarios durante todo el año. Anímate a escribirnos.',

}

const serviceDS = {
  subtitle: 'Inti Kamari',
  salon: 'Salones',
  salonDetail: 'En diferentes puntos dentro de Inti Kamari, contamos con salas abrazadas bellamente por el verdor de la fauna y flora autóctonas de la zona. Ideales para yoga, meditación, eventos o simplemente para tomarse un respiro de aire fresco.',
  room: 'Habitaciones',
  roomDetail: ' Imagínate despertar rodeado de huertos orgánicos o bosques frutales. Una experiencia indescriptible la que te brindan nuestras habitaciones. Todas cuentan con los elementos necesarios para garantizar ese momento de relax y sueño.',
  library: 'Biblioteca',
  libraryDetail: ' ¿Amantes de la lectura? Contamos con una amplia biblioteca que abarca diferentes géneros literarios, ideales para una lectura en cualquier momento del día. Escoge tu tomo favorito y viaja a través de una experiencia sensorial que solo un buen libro te puede brindar.',
  greenAreas: 'Áreas Verdes',
  greenAreasDetail: 'Son 30 000 mts² de agricultura regenerativa y sustentable las que rodean a Inti Kamari. Estas áreas verdes favorecen el bienestar físico y emocional de nuestros huéspedes, ya que todo nuestro hogar se encuentra rodeado de un verdor único. Son usados comúnmente para actividades físicas, de desprendimiento o para una caminata tranquila.',
  food: 'Alimentos',
  foodDetail: 'Basamos nuestra alimentación dentro de la rama “consciente”, es decir, usamos ingredientes orgánicos, extraídos de nuestro huerto, que permiten una comida deliciosa y saludable. Contamos con menú vegetariano, vegano, menú regular con carne, pollo o pescado y de niños.',

  activity: 'Actividades',
  activityDetail1: 'Brindamos, además de nuestro hospedaje, una serie de actividades que van desde:',
  activityDetail2: '- Yoga Matutino',
  activityDetail3: '- Tours de Agroturismo por nuestro Laboratorio de Setas y Hongos',
  activityDetail4: '- Comestibles',
  activityDetail5: '- Caminatas a zonas turísticas aledañas',
  activityDetail6: '- Cabalgata',
  activityDetail7: '- Recorridos por nuestra finca',
  activityDetail8: 'O simplemente lo que tú desees hacer, con respeto y cuidado a cada uno de nuestros espacios que cuidamos con mucho cariño.',

}

const additionalServices = {
  title: 'Servicios Adicionales',
  pet: 'Pet Friendly',
  petDetail1: "En Inti Kamari, amamos y respetamos a toda criatura que nos visita.  Ante esto, si vienes con tu peludito recuerda que no sea dominante, sea educado, no duerma en las camas, limpies sus desechos, no salten a la piscina y traigan su respectiva correa o arnés.",
  petDetail2: "Todo esto porque compartimos el espacio con varias especies, entre ellas están: gansos, ardillas, muchos pajaritos y gatos.",

  food: 'Alimentación Consciente',
  foodDetail1: 'Contamos con un restaurante con comida deliciosa, hecha con recetas tradicionales, a base de ingredientes extraídos de nuestra huerta orgánico.',
  foodDetail2: 'Esto nos permite mantener nuestra filosofía de alimentación consciente que se basa en mejorar la forma en la que comemos y cómo puede beneficiar esto a la salud de cada huésped.',
  foodDetail3: '¡Te invitamos a disfrutarla!',
}

export const home = {
  header: headerDS,
  abaut: abautDS,
  statistics: statisticsDS,
  maps: mapsDS,
  natureService: natureServiceDS,
  service: serviceDS,
  additionalServices: additionalServices,
  footer: "Más de seis mil personas nos han visitado en estos últimos 6 años, en más de doscientos encuentros y retiros. Nuestra fundación ha ofrecido un centenar de talleres, excursiones y brigadas médicas a favor de la comunidad de Quillosisa, Santa Isabel.",
}
