
const section1DS = {
  title: "Una Pausa Natural",
  subtitle: "Crece, Transformate, Sana",
  body: "Abrimos las puertas de nuestro hogar a eventos sociales, capacitaciones empresariales o retiros de bienestar. Somos el lugar perfecto para la creación de momentos inolvidables, la reconexión con tu equipo de trabajo o simplemente para retiros de crecimiento personal. Nuestra experiencia facilitará el desarrollo de estos encuentros.",
}

const section2DS = {
  body1: "Retiros de parejas, espirituales, del silencio",
  body2: "Meditacion y Yoga",
  body3: "Arte y ciencia, seminarios, trabajo en equipo",
  body4: "Neurociencia y Psicologia",
}

const section3DS = {
  title: "Una Pausa Natural",
  subtitle: "Tipo De Retiros",
  
  subtitle1: "Detalle",
  subtitle2: "Organizadores",
  
  body1l1: "Promovemos la realización de retiros de distintas corrientes. Extraemos nuestros conocimientos desde las rigurosas ciencias, hasta las antiguas tradiciones y religiones de todo el mundo.",
  body1l2: "Damos apertura a disciplinas que van desde la psicología y las neurociencias hasta la meditación y el yoga. La permacultura, la nutrición, la medicina ancestral y el arte, se encuentran entre nuestro arsenal de herramientas.",
  body1l3: "Un retiro podría ser el último empujón que necesitas, traer revelaciones inesperadas o darte la relajación más valiosa que hayas tenido en años.",
  
  body2l1: "Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.",
  body2l2: "Durante los últimos años, Cuenca silenciosamente se ha desarrollado para convertirse en una de las ciudades de Sudamérica más activas espiritualmente.",
  body2l3: "Abrimos la posibilidad a organizadores y facilitadores provenientes de otros centros, para compartir propuestas y proyectos de crecimiento personal y espiritual. Podemos darte tanta libertad como desees o tanta ayuda como requieras con la organización de tu retiro.",
}

export const retiro = {
  section1: section1DS,
  section2: section2DS,
  section3: section3DS,

};
