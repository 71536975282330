
const headerDS = {
  header1: "Nuestro compromiso está con la comunidad de Quillosisa y zonas aledañas de Santa Isabel, Azuay.",
  header2: "Participamos en la creación del Parque Público Agroecológico, murales y varios eventos en los que participan los niños de nuestra fundación.",
  header3: "Además, recibimos voluntarios nacionales y extranjeros que deseen ayudar y",
  header4: "comprometerse con las tareas diarias que existen dentro de Inti Kamari.",

}

const roomTypeDS = {
  title: "Habitaciones",
  subtitle: "Servicios",
  detail: "¡Aprovecha nuestros espacios disponibles y planifica tu estancia desde hoy!",
  room1: "Habitacion Doble",
  room1Detail: "Habitación doble con baño privado, zona de estar, zona de comedor y vistas al jardín.",
  room2: "Habitacion Triple",
  room2Detail: "Habitación triple con baño privado, zona de estar, zona de comedor y vistas al jardín.",
  room3: "Acceso a Instalaciones",
  room3Detail: "Áreas verdes, restaurant, salas, etc",
}

const roomPriceDS = {
  title: "Precios",
  subtitle: "Habitaciones",
  detail1: "Todos los valores incluyen impuestos, uso de todas las instalaciones y clases de yoga por la mañana.",
  detail2: "Recuerda:",
  detail3: "Estos precios son promocionales con pago en efectivo, depósito o transferencia e incluyen impuestos.",

  room1: "HABITACION DOBLE O MATRIMONIAL",
  room1D1: "Valor por adulto:",
  room1D2: "Valor por niño de 5 -12 años:",
  room1D3: "* Incluye desayuno", 
  room2: "HABITACION INDIVIDUAL",  
  room3: "TERCER ADULTO EN HABITACIÓN",  
  room4: "ALIMENTACION",
  room4D1: "Almuerzo o Cena por adulto:",
  room4D2: "Almuerzo o Cena por niño hasta 12 años:",

}

const roomDetailDS = {
  title1: "Hermoso Y Pacífico",
  title2: "Lugar Para Todos",

  s1: "Idiomas",
  s1d1: "Ingles",
  s1d2: "Español",

  s2: "Recreación",
  s2d1: "Piscina al aire libre",
  s2d2: "Biblioteca",

  s3: "Habitaciones",
  s3d1: "Wi-Fi en algunas habitaciones",
  s3d2: "Habitaciones familiares",
  s3d3: "Terraza, Jardin",

  s4: "Servicios Adicionales",
  s4d1: "Parking gratis privado",
  s4d2: "Cocina compartida",
  s4d3: "Bañera de hidromasaje / jacuzzi",
  s4d4: "Zona TV / salón de uso compartido",

}


export const roomDS = {
  header: headerDS,
  roomType: roomTypeDS,
  roomPrice: roomPriceDS,
  roomDetail: roomDetailDS
}