import { Col, Row } from 'antd';
import { RedesSocialesLinkDs } from 'assets/data/data.source.contacto';
import { ActividadesDataSource, GaleriaHabitacionDS, HospedajeDataSource, PrecioHabitacionDS, TipoHabitacionDS } from 'assets/data/data.source.hospedaje';
import CustomPage from 'components/page/CustomPage';
import SectionGaleria from 'components/section/SectionGaleria';
import SectionListOptions from 'components/section/SectionListOptions';
import SectionOptions from 'components/section/SectionOptions';
import SectionReservar from 'components/section/SectionReservar';
import { isEspanol } from 'config/localStorageControl';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { rutas } from 'router/rutas';

const aboutbg = require('assets/images/intiBackground/bg5.jpg'); 
const titulo = require('assets/images/intiHabitacion/titulo-trs.png');
const tituloIngles = require('assets/images/intiHabitacion/titulo-ingles.png');

class Hospedaje extends Component {


    render() {
        const informacionDS = HospedajeDataSource(this.props.t);
        const tipoHabitacionDS = TipoHabitacionDS(this.props.t);
        const precioHabitacionDS = PrecioHabitacionDS(this.props.t);
        const actividadesDS = ActividadesDataSource(this.props.t);
        const galeriaHabitacionDS = GaleriaHabitacionDS(this.props.t);

        return (
            <CustomPage header
                image={aboutbg}
                title={this.props.t(rutas.apartamentos.id)}
                subtitle={this.props.t(rutas.apartamentos.subtitle)}>
                <br />  <br />
                <div class="home-page portfolio-details">
                    <div class="auto-container">
                        <div class="top-content">
                            <div class="image-box">
                                <img src={require('assets/images/intiBackground/HABITACIONES.jpg')} alt="" />
                            </div>
                            <div class="single-project-info">
                                <h3> {informacionDS.contacto.title} </h3>
                                <table class="cart-table">
                                    <tbody>
                                        {informacionDS.contacto.body.map((item, index) =>
                                            <tr>
                                                <td><strong>{item.label}</strong></td>
                                                <td>{item.value}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {RedesSocialesLinkDs}
                            </div>
                        </div>
                        <br />
                        <div class="sec-title">
                            <img class="img" src={isEspanol() ? titulo : tituloIngles} alt="img" />
                        </div>
                        <div class="text">
                            {informacionDS.body}
                        </div>
                    </div>
                </div>
                <br /><br />



                {/* <!-- Services Section Two --> */}
                <section class="services-section-two services-section-careers">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column" style={{ backgroundImage: "url(" + aboutbg + ")" }}>
                                <div class="sec-title light">
                                    <div class="sub-title">{tipoHabitacionDS.subtitle}</div>
                                    <h2>{tipoHabitacionDS.title}</h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div class="contact-form">
                                    <div class="text">
                                        {tipoHabitacionDS.body}
                                    </div>
                                </div>
                            </div>
                            <div class="style1">
                                <div class="">
                                    <div class="services-content">
                                        <Row gutter={25}>
                                            {
                                                tipoHabitacionDS.detailCategoy.map((header, i) => {
                                                    return (
                                                        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                                                            <div class="news-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                                                <div class="inner-box">
                                                                    <div class="image">
                                                                        <img src={header.img} alt="" />
                                                                    </div>
                                                                    <div class="lower-content">
                                                                        <div class="category">{header.title}</div>
                                                                        <h3><Link to={'/blog-details'}>{header.title}</Link></h3>
                                                                        <div class="text">{header.category}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />  <br />
                <br />  <br />
                <SectionOptions dataSource={precioHabitacionDS} />
                <br />  <br />
                <SectionReservar />
                <SectionListOptions dataSource={actividadesDS} />
                <SectionGaleria dataSource={galeriaHabitacionDS} />

            </CustomPage>
        )
    }
}
export default withTranslation()(Hospedaje);
/**
 * 
 */