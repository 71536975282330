import { UbicacionDs } from 'assets/data/data.source.home';
import ReservarLink from 'components/link/ReservarLink';
import React from 'react';

const AddressMap = (props) => {
  return (
    <section class="about-section-two">
      <div class="auto-container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="content-box style-two">
              <div class="sec-title">
                <div class="sub-title">{props.t(UbicacionDs.subtitle)}</div>
                <h2>{props.t(UbicacionDs.title)}</h2>
                <div class="text">
                  {props.t(UbicacionDs.body)}
                </div>
                <br />
                <div class="btn-box">
                  <ReservarLink />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div className="google-map-code">
              <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3983.326330579613!2d-79.2939286!3d-3.2689546!3m2!1i1024!2i768!4f13.1!3m2!1m1!2sInti%20Kamari!5e0!3m2!1ses-419!2sec!4v1648480420022!5m2!1ses-419!2sec"
                width="100%"
                height="500"
                frameborder="0" style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0">

              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
export { AddressMap };

