import { Col, Row } from 'antd';
import { GaleriaVoluntariosDS, HabilidadesDs, ResponsabilidadDs, TipoVoluntarioDs } from 'assets/data/data.source.voluntarios';
import CustomPage from 'components/page/CustomPage';
import SectionGaleria from 'components/section/SectionGaleria';
import SectionTabs2 from 'components/section/SectionTabs2';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';
import { withTranslation } from 'react-i18next';



const aboutbg = require('assets/images/intiBackground/bg7.jpg');

class Voluntarios extends Component {


    render() {
        var ds = ResponsabilidadDs(this.props.t);
        var tipoVoluntarioDs = TipoVoluntarioDs(this.props.t);
        var habilidadesDs = HabilidadesDs(this.props.t);

        return (
            <CustomPage header
                image={aboutbg}
                title={this.props.t(rutas.voluntarios.id)}
                subtitle={this.props.t(rutas.voluntarios.subtitle)}>

                <br />  <br />

                <SectionTabs2
                    dataSource={tipoVoluntarioDs} />

                <br />  <br />
                {/* <!-- Services Section Two --> */}
                <section class="services-section-two services-section-careers">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="left-column" style={{ backgroundImage: "url(" + aboutbg + ")" }}>
                                <div class="sec-title light">
                                    <div class="sub-title">{habilidadesDs.subtitle}</div>
                                    <h2>{habilidadesDs.title}</h2>
                                </div>
                            </div>
                            <div class="style2">
                                <div class="services-content">

                                    <Row gutter={25}>
                                        {
                                            habilidadesDs.detailCategoy.map((header, i) => {
                                                return (
                                                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                        <div class="icon-box">
                                                            <div class="icon"><img src={header.img} alt="" /></div>

                                                            <div class="text">{header.title}</div>
                                                        </div>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <br />  <br />
                <section class="about-section-two">
                    <div class="auto-container">

                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box style-two">
                                    <div class="sec-title">
                                        <div class="sub-title">{ds.subtitle}</div>
                                        <h2>{ds.title}</h2>
                                        <div class="text">
                                            {ds.body}
                                        </div>
                                    </div>
                                    <div class="lower-content">
                                        <ul class="list-box">
                                            {ds.list.map((item, index) =>
                                                <li><i class="fa fa-check"> </i>{item.label}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('assets/images/intiVoluntarios/1.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <SectionGaleria
                    dataSource={GaleriaVoluntariosDS(this.props.t)} />

            </CustomPage>
        )
    }
} 
export default withTranslation()(Voluntarios);
/**
 * 
 */