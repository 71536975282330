import { HeaderDataSource } from 'assets/data/data.source.home';
import 'assets/less/antMotionStyle.less';
import AnimationVertical from 'components/less/AnimationVertical';
import LinkComponent from 'components/link';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { seccionHome } from 'router/rutas'; 
import { withTranslation } from 'react-i18next';

class SectionHeader extends Component {

    getHeader = () => {
        let datos = [];
        HeaderDataSource.children.map((item, i) => {
            datos.push(
                <div key={'card' + i} class="welcome-block-one col-md-4  col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                    <div class="inner-box" style={item.style ? item.style : {}}>

                        <div key={item.name + "1"} class="icon-box"><span class={item.icon}></span></div>
                        <h2 key={item.name + "2"} >{this.props.t(item.title)}</h2>
                        <div key={item.name + "3"} class="text">{this.props.t(item.description)}</div>
                        <div key={item.name + "4"} class="read-more">
                        </div>
                        <LinkComponent btn to={item.href} >{this.props.t(item.linklabel)} 
                        </LinkComponent>
                    </div>
                </div>
            );
        });
        return datos;
    }

    render() { 
        return (
            <>
                <section key={'sec' + 1} id={seccionHome.seccion1} class="home-page welcome-section">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="row m-0">
                                {this.getHeader()}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
export default withTranslation()(SectionHeader);