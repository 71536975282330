import { Col, Row } from 'antd';
import { RedesSocialesLinkDs } from 'assets/data/data.source.contacto';
import { ContactoDs } from 'assets/data/data.source.contacto';
import LinkComponent from 'components/link';
import ReservarLink from 'components/link/ReservarLink';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { rutas } from 'router/rutas';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
    render() {
        return (
            <>
                <footer class="footer-page  main-footer">
                    <div class="auto-container">

                        <div class="widgets-section">
                            <div class="row clearfix">


                                <div class="column col-sm-12  col-md-12 col-lg-4 ">
                                    <div class="footer-widget logo-widget">
                                        <div class="widget-content">
                                            <div class="footer-logo">
                                                <LinkComponent to="/"><img width={'30%'} class="" src={require('assets/images/Logo-dark2.png')} alt="" /></LinkComponent>
                                            </div>
                                            <div class="text">
                                                {this.props.t('home.footer')}
                                            </div>
                                            {RedesSocialesLinkDs}
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <div class="column col-sm-6 col-md-6 col-lg-5">
                                    <div class="footer-widget links-widget">
                                        <div class="widget-content">
                                            <div class="row">
                                                <div class=" col-sm-12 col-md-6">
                                                    <div class="sidebar-title"><p>MENU</p></div>
                                                    <ul>
                                                        <li><LinkComponent to={rutas.home.href}>{this.props.t(rutas.home.id)}</LinkComponent></li>
                                                        <li><LinkComponent to={rutas.comunidad.href}>{this.props.t(rutas.comunidad.id)}</LinkComponent></li>
                                                        <li><LinkComponent to={rutas.galeria.href}>{this.props.t(rutas.galeria.id)}</LinkComponent></li>
                                                    </ul>
                                                    <br />
                                                </div>
                                                <div class=" col-sm-12 col-md-6">
                                                    <div class="sidebar-title"><p>{this.props.t('menu.service')}</p></div>
                                                    <ul>
                                                        <li><LinkComponent to={rutas.retiros.href}>{this.props.t(rutas.retiros.id)}</LinkComponent></li>
                                                        <li><LinkComponent to={rutas.apartamentos.href}>{this.props.t(rutas.apartamentos.id)}</LinkComponent></li>
                                                        <li><LinkComponent to={rutas.voluntarios.href}>{this.props.t(rutas.voluntarios.id)}</LinkComponent></li>
                                                    </ul>
                                                </div>
                                                <div class=" col-sm-12 col-md-12">
                                                    <div class="sidebar-title"><p>{this.props.t('menu.location')}</p></div>

                                                    <ul>
                                                        <li>
                                                            <Row gutter={30}>
                                                                <div key="ab6" class="icon">
                                                                    <span class={ContactoDs.location.icono}></span>
                                                                </div>
                                                                <Col >
                                                                    <ul>
                                                                        <li> {ContactoDs.location.link}   </li>
                                                                    </ul>
                                                                </Col>
                                                            </Row>
                                                        </li>
                                                        <li>
                                                            <Row justify='start' gutter={30}>
                                                                {ContactoDs.booking.link}
                                                                &nbsp;&nbsp;
                                                                {ContactoDs.maps.link}
                                                                &nbsp;&nbsp;
                                                            </Row>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="column col-sm-6 col-md-6 col-lg-3">
                                    <div class="footer-widget links-widget">
                                        <div class="widget-content">
                                            <div class="sidebar-title"><p>{this.props.t('menu.contactanos')}</p></div>
                                            <div class='phone'>
                                                <Row gutter={30}>
                                                    <div key="ab6" class="icon">
                                                        <span class={ContactoDs.phone1.icono}></span>
                                                    </div>
                                                    <Col  >
                                                        <ul>
                                                            <li>
                                                                {ContactoDs.phone1.link}
                                                            </li>
                                                            <li>
                                                                {ContactoDs.phone2.link}
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br />
                                            <div class='phone'>
                                                <Row gutter={30}>
                                                    <div key="ab6" class="icon">
                                                        <span class={ContactoDs.whatsapp.icono}></span>
                                                    </div>
                                                    <Col  >
                                                        <ul>
                                                            <li>
                                                                {ContactoDs.whatsapp.link}
                                                            </li>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br />
                                            <Row gutter={30}>
                                                <div key="ab6" class="icon">
                                                    <span class={ContactoDs.email.icono}></span>
                                                </div>
                                                <Col >
                                                    <ul>
                                                        <li>{ContactoDs.email.link}</li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                            <br />
                                            <div class="btn-box">
                                                <ReservarLink />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="auto-container">
                        <div class="footer-bottom">
                            <div class="copyright">Copyright By<Link to={'/'}> Arc Innovaciones</Link> - 2022</div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default withTranslation()(Footer);