import ReservarLink from 'components/link/ReservarLink';
import React from 'react';

//#311703

export function RetiroTabDataSource(t) {
    return {
        title: t('retiro.section3.subtitle'),
        subtitle: t('retiro.section1.subtitle'),
        title2: t('retiro.section1.subtitle'),
        subtitle2: t('retiro.section1.title'),
        image1: require('assets/images/intiRetiro/2.png'),
        image2: require('assets/images/intiRetiro/1.png'),
        body: t('retiro.section1.body'),
        detailCategoy: [
            {
                eventKey: 'first',
                category: t('retiro.section3.subtitle1'),
                body: {
                    image: require('assets/images/intiResource/5.png'),
                    description:
                        <>
                            {t('retiro.section3.body1l1')} <br />
                            {t('retiro.section3.body1l2')}<br />
                            {t('retiro.section3.body1l3')}<br />
                        </>,
                }
            },
            {
                eventKey: 'second',
                category: t('retiro.section3.subtitle2'),
                body: {
                    image: require('assets/images/intiResource/5.png'),
                    description:
                        <>
                            {t('retiro.section3.body2l1')} <br />
                            {t('retiro.section3.body2l2')}<br />
                            {t('retiro.section3.body2l3')}<br />
                        </>,
                }
            },
        ],
    }
};


export function ActividadesDataSource(t) {
    return {
        title: t('retiro.section1.subtitle'),
        subtitle: t('retiro.section1.title'),
        body: (<>
            {t('retiro.section1.body')} <br />
            <br />
            <div class="btn-box">
                <ReservarLink />
            </div>
        </>),
        detailCategoy: [
            {
                eventKey: 'third',
                category: t('retiro.section2.body1'),
                icon: 'flaticon-user',
            },
            {
                eventKey: 'first',
                category: t('retiro.section2.body2'),
                icon: 'flaticon-team',
            },
            {
                eventKey: 'fourth',
                category: t('retiro.section2.body3'),
                icon: 'flaticon-world',
            },
            {
                eventKey: 'second',
                category: t('retiro.section2.body4'),
                icon: 'flaticon-notebook',
            },
        ],
    }
};

