const section1DS = {
  title: "A Natural Pause",
  subtitle: "Grow, Transform, Heal",
  body: "We open the doors of our home to social events, business trainings or wellness retreats. We are the perfect place to create unforgettable moments, reconnect with your work team or simply for personal growth retreats. Our experience will facilitate the development of these meetings.",
}

const section2DS = {
  body1: "Retreats for couples, spiritual, silence",
  body2: "Meditation and Yoga",
  body3: "Art and science, seminars, teamwork",
  body4: "Neuroscience and Psychology",
}

const section3DS = {
  title: "A Natural Pause",
  subtitle: "Type Of Withdrawals",
  subtitle1: "Detail",
  subtitle2: "Organizers",
  
  body1l1: "We promote the realization of retreats of different currents. We extract our knowledge from the rigorous sciences, to the ancient traditions and religions of the whole world.",
  body1l2: "We open up disciplines ranging from psychology and neuroscience to meditation and yoga. Permaculture, nutrition, ancient medicine and art are among our arsenal of tools.",
  body1l3: "A retreat could be the last push you need, bring unexpected revelations, or give you the most valuable relaxation you've had in years.",
  
  body2l1: "We can give you as much freedom as you want or as much help as you need with the organization of your retreat.",
  body2l2: "Over the last few years, Cuenca has quietly developed into one of the most spiritually active cities in South America.",
  body2l3: "We open the possibility to organizers and facilitators from other centers, to share proposals and projects for personal and spiritual growth. We can give you as much freedom as you wish or as much help as you require with the organization of your retreat.",
}

export const retiro = {
  section1: section1DS,
  section2: section2DS,
  section3: section3DS,

};