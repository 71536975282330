import { Spin } from 'antd';
import BackToTop from 'layout/backToTop';
import LanguageButton from 'layout/languageButton';
import SocialButton from 'layout/socialButton';
import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Comunidad from 'views/Comunidad/';
import Galeria from 'views/Galeria';
import Home from 'views/Home/';
import Hospedaje from 'views/Hospedaje/';
import Retiros from 'views/Retiros/';
import Voluntarios from 'views/Voluntarios';

class Router extends Component {
    render() {
        return (
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
                {/* <BrowserRouter> */}
                <div class="page-wrapper">
                    <Switch>
                        <Suspense
                            fallback={
                                <div className="spin">
                                    <Spin />
                                </div>
                            }
                        >
                            <Route path='/' exact component={Home} />
                            <Route path='/retiros' component={Retiros} />
                            <Route path='/hospedaje' component={Hospedaje} />
                            <Route path='/comunidad' component={Comunidad} />
                            <Route path='/voluntarios' component={Voluntarios} />
                            <Route path='/galeria' component={Galeria} />
                        </Suspense>
                    </Switch>
                    <BackToTop />
                    <SocialButton />
                    <LanguageButton /> 
                </div>
            </BrowserRouter>
        )
    }
}
export default Router;