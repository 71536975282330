import React from 'react';

export function TipoVoluntarioDs(t) {
    return {
        title: t('voluntario.section1.title'),
        subtitle: t('voluntario.section1.subtitle'),
        image1: require('assets/images/intiGallery/actividades/6.jpg'),
        detailCategoy: [
            {
                eventKey: 'first',
                category: '',
                body: {
                    image: require('assets/images/intiResource/5.png'),
                    description:
                        <>
                            {t('voluntario.section1.body1')}<br /><br />
                            {t('voluntario.section1.body2')}

                        </>,
                }
            },

        ],
    }
};

export function HabilidadesDs(t) {
    return {
        title: t('voluntario.section2.title'),
        subtitle: t('voluntario.section2.subtitle'),
        md: " col-md-3 col-lg-3",
        nocenter: true,
        detailCategoy: [
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h1.png'),
                title: t('voluntario.section2.item1'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h2.png'),
                title: t('voluntario.section2.item2'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h3.png'),
                title: t('voluntario.section2.item3'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h4.png'),
                title: t('voluntario.section2.item4'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h5.png'),
                title: t('voluntario.section2.item5'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h6.png'),
                title: t('voluntario.section2.item6'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h7.png'),
                title: t('voluntario.section2.item7'),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'second',
                img: require('assets/images/intiVoluntarios/h8.png'),
                title: t('voluntario.section2.item8'),
                icon: 'flaticon-notebook',
            },
        ],
    }
};

export function ResponsabilidadDs(t) {
    return {
        title: t('voluntario.section3.title'),
        subtitle: t('voluntario.section3.subtitle'),
        body: t('voluntario.section3.body'),
        list: [
            {
                label: t('voluntario.section3.item1')
            },
            {
                label: t('voluntario.section3.item2')
            },
            {
                label: t('voluntario.section3.item3')
            },
            {
                label: t('voluntario.section3.item4')
            },
            {
                label: t('voluntario.section3.item5')
            },
            {
                label: t('voluntario.section3.item6')
            },
            {
                label: t('voluntario.section3.item7')
            },
        ],
    }
};

export function GaleriaVoluntariosDS(t) {
    return {
        title: t('voluntario.galery'),
        subtitle: t('voluntario.section3.subtitle'),
        images: [
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g1.jpg'),

            },
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g2.jpg'),

            },
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g3.jpg'),

            },
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g4.jpg'),

            },
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g5.jpg'),

            },
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g6.jpg'),

            },
            {
                name:  t('voluntario.section3.subtitle'),
                img: require('assets/images/intiVoluntarios/g7.jpg'),

            },
        ]

    }
}