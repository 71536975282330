const section1DS = {
  title: 'Our center is always open to volunteers',
  subtitle: 'Volunteering',
  body1: "Our volunteering has no cost. The work is 3 to 4 hours a day for 5 days, with 2 days off a week. You will receive completely free accommodation, except for your food. We are constantly looking for people who want to take care of our organic garden, offer Yoga sessions or carry out art projects.",
  body2: "It is essential to present your personal proposal to be developed within our home.",
}

const section2DS = {
  title: 'Priority Skills',
  subtitle: 'Volunteers',
  item1: "Kitchen",
  item2: "Arts",
  item3: "Yoga instructors",
  item4: "Web designer, social networks",
  item5: "Photographers",
  item6: "Hosting",
  item7: "Permaculture, agriculture, gardening",
  item8: "Recreators, physical teachers, pedagogues",
}

const section3DS = {
  title: 'Responsibilities',
  subtitle: 'Volunteering',
  body: "As we have been travelers, we know about that search for a place to stay. And we always require extra hands for all the tasks in the center, such as:",
  item1: "Cleaning and general hygiene",
  item2: "Nature: orchard, gardens, harvest, etc",
  item3: "Foundation – work with children",
  item4: "Kitchen for retreats",
  item5: "Reception and hosting for retreats",
  item6: "Production",
  item7: "Art",
}
 
export const voluntario = {
  section1: section1DS,
  section2: section2DS,
  section3: section3DS,
  galery: "Gallery"

};