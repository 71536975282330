import { comunidad } from "./comunidad";
import { home } from "./home";
import { menu } from "./menu";
import { retiro } from "./retiro";
import { roomDS } from "./room";
import { voluntario } from "./voluntario";

const contactDS = {
  phone: "Teléfono",
  mail: "Correo",
  location: "Ubicación",
}

const galeryDS = {
  title: 'Galeria',
  all: 'Todas',
  act: "Actividades",
  inst: "Instalaciones",
  nat: "Naturaleza", 
}

export const TRANSLATIONS_ES = {
  language: 'Lenguaje',
  english: 'Ingles',
  spain: 'Español',
  detail: 'Detalle',
  more: 'Ver Más',
  reserve: 'Reservar',
  home: home,
  comunidad: comunidad, 
  room: roomDS,
  retiro: retiro,
  contact: contactDS,
  voluntario: voluntario,
  galery: galeryDS,
  menu: menu
};
