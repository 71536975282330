export const subtitle = {
  principal: "Main",
  comunidad: "Community",
  voluntarios: "Volunteers",
  retiros: "Retreats and Events",
  hospedaje: "Rooms and Apartments",
  galeria: "Inti Kamari",
  contacto: "Main",
  contactanos: "Main",  
};

export const menu = {
  principal: "MAIN",
  comunidad: "COMMUNITY",
  voluntarios: "VOLUNTEERS",
  retiros: "RETREATS",
  hospedaje: "ROOMS",
  galeria: "GALLERY",
  contacto: "CONTACT",
  contactanos: "CONTACT US",  
  service: "SERVICES",  
  location: "LOCATION",  
  subtitle: subtitle
};