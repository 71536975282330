import { Select } from 'antd';
import { getItem } from 'config/localStorageControl';
import { setItem } from 'config/localStorageControl';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleChangeLng } from 'translations/i18n';


const validateLng = () => {
  var lng = getItem("@inti_lng");
  if (!lng || lng == undefined)
    return 'spain';
  return lng;
}


const Lenguaje = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    flag: validateLng(),
  });

  const { flag } = state;

  const options = [
    { value: 'english', label: t('english') },
    { value: 'spain', label: t('spain') },
  ]

  useEffect(() => {
    handleChangeLng(flag);
  }, [flag]); // <- add the count variable here

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
    console.log('----------------- value', value);
    setItem("@inti_lng", value);
  };


  return (
    <>
      <div class="language">
        <form action="#" class="language-switcher">
          {flag == 'english' && (
            <div onClick={() => onFlagChangeHandle('spain')} >
              <img src={require('assets/images/flag/spain.png')} alt="" />
            </div>
          )}

          {flag == 'spain' && (
            <div onClick={() => onFlagChangeHandle('english')} >
              <img src={require('assets/images/flag/english.png')} alt="" />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default Lenguaje;
