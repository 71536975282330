import React from 'react';
import { ContactoDs } from './data.source.contacto.js';


export function HospedajeDataSource(t) {
    return {
        title: t('room.roomType.title'),
        subtitle: (<>{t('room.roomDetail.title1')} <br /> {t('room.roomDetail.title2')}</>),
        body: (
            <>
                {t('room.header.header1')}<br />
                {t('room.header.header2')}<br />
                {t('room.header.header3')}<br />
                {t('room.header.header4')}<br />
            </>),
        contacto: {
            title: t('menu.contactanos'),
            body: [
                {
                    label: t(ContactoDs.subtituloLocation),
                    value: ContactoDs.location.label,
                },
                {
                    label: t(ContactoDs.subtituloEmail),
                    value: ContactoDs.email.label,
                },
                {
                    label: t(ContactoDs.subtituloPhone),
                    value: ContactoDs.phone1.label,
                },
                {
                    label: '',
                    value: ContactoDs.phone2.label,
                },
            ]
        }
    }
};

export function TipoHabitacionDS(t) {
    return {
        title: t('room.roomType.title'),
        subtitle: t('room.roomType.subtitle'),
        md: "col-md-4 col-lg-4",
        body: t('room.roomType.detail'),
        detailCategoy: [
            {
                eventKey: 'third',
                title: t('room.roomType.room1'),
                category: t('room.roomType.room1Detail'),
                icon: 'flaticon-money-1',
                img: require('assets/images/intiHabitacion/8.jpg'),
            },
            {
                eventKey: 'first',
                title: t('room.roomType.room2'),
                category: t('room.roomType.room2Detail'),
                icon: 'flaticon-team',
                img: require('assets/images/intiHabitacion/1.jpg'),
            },
            {
                eventKey: 'second',
                title: t('room.roomType.room3'),
                category: t('room.roomType.room3Detail'),
                icon: 'flaticon-world',
                img: require('assets/images/intiServicios/instalaciones.png'),
            },
        ],
    }
};

export function PrecioHabitacionDS(t) {
    return {
        title: t('room.roomPrice.title'),
        subtitle: t('room.roomPrice.subtitle'),
        md: "col-md-3 col-lg-3",
        body:
            <>
                {t('room.roomPrice.detail1')}<br />
                {t('room.roomPrice.detail2')}<br />
                {t('room.roomPrice.detail3')}<br />
            </>,
        detailCategoy: [
            {
                eventKey: 'first',
                title: t('room.roomPrice.room1'),
                category: <>{t('room.roomPrice.room1D1')}<br />
                    $ 49,50<br />
                    {t('room.roomPrice.room1D2')}<br />
                    $ 33,50<br />
                    {t('room.roomPrice.room1D3')}
                </>,
                icon: 'flaticon-money-1',
                img: require('assets/images/intiHabitacion/1.jpg'),
            },
            {
                eventKey: 'second',
                title: t('room.roomPrice.room2'),
                category: <>{t('room.roomPrice.room1D1')}<br />
                    $ 63,50<br />
                    {t('room.roomPrice.room1D3')}
                </>,
                icon: 'flaticon-money-1',
                img: require('assets/images/intiServicios/instalaciones.png'),
            },
            {
                eventKey: 'third',
                title: t('room.roomPrice.room3'),
                category: <>{t('room.roomPrice.room1D1')}<br />
                    $ 39,50<br />
                    {t('room.roomPrice.room1D3')}
                </>,
                icon: 'flaticon-money-1',
                img: require('assets/images/intiHabitacion/8.jpg'),
            },
            {
                eventKey: 'four',
                title: t('room.roomPrice.room4'),
                category: <>{t('room.roomPrice.room4D1')}<br />
                    $ 9,50<br />
                    {t('room.roomPrice.room4D2')}<br />
                    $ 7,50
                </>,
                icon: 'flaticon-money-1',
                img: require('assets/images/intiHabitacion/8.jpg'),
            },
        ],
    }
};

export function ActividadesDataSource(t) {
    return {
        title: (<> {t('room.roomDetail.title1')}<br />  {t('room.roomDetail.title2')}</>),
        subtitle: 'Inti Kamari',
        detailCategoy: [
            {
                eventKey: 'second',
                img: require('assets/images/icons/inti-icon-5.png'),
                title: t('room.roomDetail.s1'),
                category: (
                    <>
                        {t('room.roomDetail.s1d1')} <br />
                        {t('room.roomDetail.s1d2')}
                    </>
                ),
                icon: 'flaticon-notebook',
            },
            {
                eventKey: 'fourth',
                img: require('assets/images/icons/inti-icon-4.png'),
                title: t('room.roomDetail.s2'),
                category: (
                    <>
                        {t('room.roomDetail.s2d1')} <br />
                        {t('room.roomDetail.s2d2')}
                    </>
                ),
                icon: 'flaticon-world',
            },
            {
                eventKey: 'third',
                img: require('assets/images/icons/inti-icon-1.png'),
                title: t('room.roomDetail.s3'),
                category: (
                    <>
                        {t('room.roomDetail.s3d1')} <br />
                        {t('room.roomDetail.s3d2')}<br />
                        {t('room.roomDetail.s3d3')}
                    </>
                ),
                icon: 'flaticon-money-1',
            },
            {
                eventKey: 'first',
                img: require('assets/images/icons/inti-icon-3.png'),
                title: t('room.roomDetail.s4'),
                category: (
                    <>
                        {t('room.roomDetail.s4d1')} <br />
                        {t('room.roomDetail.s4d2')} <br />
                        {t('room.roomDetail.s4d3')}<br />
                        {t('room.roomDetail.s4d4')}
                    </>
                ),
                icon: 'flaticon-team',
            },
        ],
    }
};

export function GaleriaHabitacionDS(t) {
    return {
        title: t('galery.title'),
        subtitle: t('room.roomType.title'),
        images: [
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/1.jpg'),

            },
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/2.jpg'),

            },
            {
                name: t('room.roomType.room2'),
                img: require('assets/images/intiHabitacion/1.jpg'),

            },
            {
                name: t('room.roomType.room2'),
                img: require('assets/images/intiHabitacion/2.jpg'),

            },
            {
                name: t('room.roomType.room2'),
                img: require('assets/images/intiHabitacion/3.jpg'),

            },
            {
                name: t('room.roomType.room2'),
                img: require('assets/images/intiHabitacion/4.jpg'),

            },
            {
                name: t('room.roomType.room1'),
                img: require('assets/images/intiHabitacion/5.jpg'),

            },
            {
                name: t('room.roomType.room1'),
                img: require('assets/images/intiHabitacion/6.jpg'),

            },
            {
                name: t('room.roomType.room1'),
                img: require('assets/images/intiHabitacion/7.jpg'),

            },
            {
                name: t('room.roomType.room1'),
                img: require('assets/images/intiHabitacion/8.jpg'),

            },
        ]

    }
}
