import { filterData, FILTER_DEFS, images } from 'assets/data/data.source.galeria';
import ServiceFilter1 from 'components/common/service-filter1';
import CustomPage from 'components/page/CustomPage';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';
import { withTranslation } from 'react-i18next';

const aboutbg = require('assets/images/intiBackground/bg4.jpg');

class Galeria extends Component {
    render() {
        return (
            <>
                <CustomPage  >
                    <ServiceFilter1
                        images={images}
                        headerImage={aboutbg}
                        titulo={this.props.t(rutas.galeria.id)}
                        subtitulo={this.props.t(rutas.galeria.subtitle)}
                        filterData={filterData(this.props.t)}
                        FILTER_DEFS={FILTER_DEFS} />
                </CustomPage>
            </>
        )
    }
} 
export default withTranslation()(Galeria);