import { ActividadesDataSource, RetiroTabDataSource } from 'assets/data/data.source.retiro';
import CustomPage from 'components/page/CustomPage';
import SectionOptions from 'components/section/SectionOptions';
import SectionTabs2 from 'components/section/SectionTabs2';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';
import { withTranslation } from 'react-i18next';

const aboutbg = require('assets/images/intiBackground/bg3.jpg');

/**
 * 
 */
class Retiros extends Component {

    render() {
        return (
            <CustomPage header
                image={aboutbg}
                title={this.props.t(rutas.retiros.id)}
                subtitle={this.props.t(rutas.retiros.subtitle)}>
                <br />
                <br />
                <SectionOptions
                    dataSource={ActividadesDataSource(this.props.t)} />
                <br />
                <SectionTabs2
                    dataSource={RetiroTabDataSource(this.props.t)} />
                <br /><br /><br />
            </CustomPage>
        )
    }
} 
export default withTranslation()(Retiros);