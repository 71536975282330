import { isEspanol } from 'config/localStorageControl';
import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { Link } from 'react-router-dom';

const slogan = require('assets/images/inti-slogan.png');
const sloganIngles = require('assets/images/inti-slogan-ingles.png');


class VideoPopup2 extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        const { ...props } = this.props;
        const { imgBackground, header, title1, title2, videoId } = props;

        return (
            <>

                <section class="video-section style-two" style={{ backgroundImage: "url(" + imgBackground + ")" }}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <h2>{title1}<br />{title2}</h2> 
                            <img src={isEspanol() ? slogan : sloganIngles} width="40%" alt="img" />

                        </div>
                        <div class="default-video-box text-center wow zoomIn" data-wow-delay="200ms" data-wow-duration="1200ms">
                            <Link onClick={this.openModal} class="overlay-link lightbox-image video-fancybox ripple">
                                <span class="fas fa-play"></span>
                            </Link>
                        </div>
                    </div>
                </section>

                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={videoId} onClose={() => this.setState({ isOpen: false })} />


            </>
        )
    }
}
export default VideoPopup2;