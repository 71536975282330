import { Row } from 'antd';
import { OnlyIconsSocialDs } from 'assets/data/data.source.contacto';
import LinkComponent from 'components/link';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from './menu';

const options = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' },
]


class Header1 extends Component {

    state = {
        scrolled: false,
    };

    componentDidMount() {
        var btn = document.querySelector('.mobile-nav-toggler');
        var closeBtn = document.querySelector('.close-btn');
        var body = document.getElementsByTagName('body')[0];

        function addFunc() {
            return body.classList.add("mobile-menu-visible");
        }
        function closeFunc() {
            return body.classList.remove("mobile-menu-visible");
        }
        btn.addEventListener('click', addFunc);
        closeBtn.addEventListener('click', closeFunc);
        window.addEventListener("scroll", this.handleScroll);
        body.classList.remove("mobile-menu-visible");
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = event => {
        if (window.scrollY > 100) {
            this.setState({ scrolled: true });
        }
        else {
            this.setState({ scrolled: false });
        }
    };

    render() {
        const { scrolled } = this.state;
        return (
            <>
                <header class={scrolled ? "main-header fixed-header" : "main-header"}>
                    <br />
                    <div class="header-upper header-normal">
                        <div class="auto-container">
                            <div class="inner-container">
                                <div class="nav-outer clearfix">

                                    <div class="top-left">
                                        {OnlyIconsSocialDs}
                                        <div class="logo-box">
                                            <div class="logo">
                                                <LinkComponent to={'/#'}>
                                                    <Row justify='center'>
                                                        <img className='header1'
                                                            src={require('assets/images/Logo_white.png')} alt="" />
                                                    </Row>
                                                </LinkComponent>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mobile-nav-toggler"><span class="icon fal fa-bars"></span></div>
                                    <nav class="main-menu navbar-expand-md navbar-light">
                                        <Menu />
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class={scrolled ? "sticky-header animated slideInDown" : "sticky-header"}>
                        <div class="auto-container clearfix">
                            <div class="logo pull-left">
                                <LinkComponent to={'/'} title="">
                                    <img width={'15%'}
                                        style={{ maxWidth: 48, minWidth: 48 }}
                                        src={require('assets/images/Logo_white.png')} alt="" />
                                </LinkComponent>
                            </div>
                            <div class="pull-right">
                                <nav class="main-menu clearfix">
                                    <Menu />
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="mobile-menu">
                        <div class="menu-backdrop"></div>
                        <div class="close-btn"><span class="icon flaticon-cancel"></span></div>
                        <nav class="menu-box">
                            <div class="nav-logo"><Link to={'/'}><img src="assets/images/logo.png" alt="" title="" /></Link></div>
                            <div class="menu-outer">
                                <Menu />
                            </div>
                        </nav>
                    </div>
                </header>
            </>
        )
    }
}
export default Header1;


