import { ComunidadDS } from 'assets/data/data.source.comunidad';
import CustomPage from 'components/page/CustomPage';
import SectionTabs2 from 'components/section/SectionTabs2';
import React, { Component } from 'react';
import { rutas } from 'router/rutas';
import { withTranslation } from 'react-i18next';


const aboutbg = require('assets/images/intiBackground/bg8.jpg');

class PortfolioDetails extends Component {


    render() {
        return (
            <CustomPage header
                image={aboutbg}
                title={this.props.t(rutas.comunidad.id)}
                subtitle={this.props.t(rutas.comunidad.subtitle)}>
                <br />
                <br />
                <SectionTabs2
                    dataSource={ComunidadDS(this.props.t)} />
                <br />
                <br />
                <br />
            </CustomPage>
        )
    }
} 
export default withTranslation()(PortfolioDetails);