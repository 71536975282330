const sc1 = require('assets/images/intiBackground/bg2.jpg');
const sc2 = require('assets/images/intiBackground/bg2.jpg');
const sc3 = require('assets/images/intiBackground/bg2.jpg');

export const FILTER_DEFS = {
    actividades: image => image.actividades,
    instalaciones: image => image.instalaciones,
    naturaleza: image => image.naturaleza,
    NONE: image => image
};

export function filterData(t) {
    return [
        {
            id: "NONE",
            label: t('galery.all')
        },
        {
            id: "actividades",
            label: t('galery.act')
        },
        {
            id: "instalaciones",
            label: t('galery.inst')
        },
        {
            id: "naturaleza",
            label:t('galery.nat')
        },
    ]
}

export const imagesActDs = () => {
    let r = [];
    for (var i = 1; i < 15; i++) {
        r.push({
            id: "actividades" + i,
            name: "actividades",
            actividades: true,
            url: 'assets/images/intiGallery/actividades/' + i + '.jpg',
            imgUrl: require('assets/images/intiGallery/actividades/' + i + '.jpg'),
        });
    }
    return r;
}

export const imagesInstDs = () => {
    let r = [];
    for (var i = 1; i < 10; i++) {
        r.push({
            id: "instalaciones" + i,
            name: "instalaciones",
            instalaciones: true,
            url: 'assets/images/intiGallery/instalaciones/' + i + '.jpg',
            imgUrl: require('assets/images/intiGallery/instalaciones/' + i + '.jpg'),
        });
    }
    return r;
}

export const imagesNatDs = () => {
    let r = [];
    for (var i = 1; i < 18; i++) {
        r.push({
            id: "naturaleza" + i,
            name: "naturaleza",
            naturaleza: true,
            url: "assets/images/intiGallery/naturaleza/" + i + ".jpg",
            imgUrl: require("assets/images/intiGallery/naturaleza/" + i + ".jpg"),
        });
    }
    return r;
}
export const images = [
    ...imagesNatDs(),
    ...imagesInstDs(),
    ...imagesActDs()
];


