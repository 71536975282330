const section1DS = {
  title: 'Nuestro centro siempre esta abierto a voluntarios',
  subtitle: 'Voluntariado',
  body1: "Nuestro voluntariado no tiene costo. El trabajo es de 3 a 4 horas diarias por 5 días, con 2 días libres a la semana. Recibirás hospedaje completamente gratuito, a excepción de tu alimentación. Estamos en la búsqueda constante de personas que quieran encargarse de nuestra huerta orgánica, brindar sesiones de Yoga o realizar proyectos de arte.",
  body2: "Es indispensable presentar tu propuesta personal a desarrollar dentro de nuestro hogar.",
}

const section2DS = {
  title: 'Habilidades Prioritarias',
  subtitle: 'Voluntarios',
  item1: "Cocina",
  item2: "Artes",
  item3: "Instructores de yoga",
  item4: "Diseñador Web, redes sociales",
  item5: "Fotografos",
  item6: "Hosting",
  item7: "Permacultura, agricultura, jardineria",
  item8: "Recreadores, profed. fisica, pedagogos",
}

const section3DS = {
  title: 'Responsabilidades',
  subtitle: 'Voluntariado',
  body: "Como hemos sido viajeros, sabemos de esa búsqueda de un lugar para quedarse. Y siempre requerimos manos extra para todas las tareas del centro, como por ejemplo:",
  item1: "Limpieza y aseo general",
  item2: "Naturaleza: huerta, jardines, cosecha, etc",
  item3: "Fundacion – trabajo con los niños",
  item4: "Cocina para retiros",
  item5: "Reception y hosting para retiros",
  item6: "Produccion",
  item7: "Arte",
}

export const voluntario = {
  section1: section1DS,
  section2: section2DS,
  section3: section3DS,
  galery: "Galeria"
};