import React from 'react';


export function ComunidadDS(t) {
    return {
        title: t('comunidad.title'),
        subtitle: 'Inti Kamari',
        image1: require('assets/images/intiResource/9.png'),
        image2: require('assets/images/intiResource/9.png'),
        detailCategoy: [
            {
                eventKey: 'first',
                category: 'Detalle',
                body: {
                    image: require('assets/images/intiResource/5.png'),
                    description:
                        (<>
                            {t('comunidad.body1')}<br />
                            {t('comunidad.body2')}<br />
                            {t('comunidad.body3')} </>),
                }
            },
        ],
    }
};
