
export const subtitle = {
  principal: "Principal",
  comunidad: "Comunidad",
  voluntarios: "Voluntarios",
  retiros: "Retiros y Eventos",
  hospedaje: "Habitaciones y Apartamentos",
  galeria: "Inti Kamari",
  contacto: "Principal",
  contactanos: "Principal", 
};

export const menu = {
  principal: "PRINCIPAL",
  comunidad: "COMUNIDAD",
  voluntarios: "VOLUNTARIOS",
  retiros: "RETIROS",
  hospedaje: "HOSPEDAJE",
  galeria: "GALERIA",
  contacto: "CONTACTO",
  contactanos: "CONTÁCTANOS",
  service: "SERVICIOS",
  location: "UBICACIÓN",
  subtitle: subtitle
};
