
const headerDS = {
  header1: "Our commitment is with the community of Quillosisa and surrounding areas of Santa Isabel, Azuay.",
  header2: "We participated in the creation of the Agroecological Public Park, murals and various events in which the children of our foundation participate.",
  header3: "In addition, we receive national and foreign volunteers who wish to help and",
  header4: "commit to the daily tasks that exist within Inti Kamari.",

}

const roomTypeDS = {
  title: "Rooms",
  subtitle: "Services",
  detail: "Take advantage of our available spaces and plan your stay from today!",
  room1: "Double Room",
  room1Detail: "Double room with private bathroom, seating area, dining area and garden views.",
  room2: "Triple Room",
  room2Detail: "Triple room with private bathroom, seating area, dining area and garden views.",
  room3: "Access to Facilities",
  room3Detail: "Green areas, restaurant, rooms, etc",
}

const roomPriceDS = {
  title: "Prices",
  subtitle: "Rooms",
  detail1: "All values ​​include taxes, use of all facilities and yoga classes in the morning.",
  detail2: "Remember:",
  detail3: "These prices are promotional with payment in cash, deposit or transfer and include taxes.",

  room1: "DOUBLE OR DOUBLE ROOM",
  room1D1: "Value per adult:",
  room1D2: "Value per child 5 -12 years old:",
  room1D3: "* Includes breakfast",

  room2: "SINGLE ROOM",
  room2D1: "Value per adult:",
  room2D2: "* Includes breakfast",

  room3: "THIRD ADULT IN ROOM",
  room3D1: "Value per adult:",
  room3D2: "* Includes breakfast",

  room4: "FEEDING",
  room4D1: "Lunch or Dinner per adult:",
  room4D2: "Lunch or Dinner per child up to 12 years old:",

}

const roomDetailDS = {
  title1: "Beautiful AND Peaceful",
  title2: "A Place For Everyone",

  s1: "Languages",
  s1d1: "English",
  s1d2: "Spanish",

  s2: "Recreation",
  s2d1: "outdoor pool",
  s2d2: "Library",

  s3: "Rooms",
  s3d1: "Wi-Fi in some rooms",
  s3d2: "Family Rooms",
  s3d3: "Terrace, Garden",

  s4: "Additional Services",
  s4d1: "Private free parking",
  s4d2: "Shared kitchen",
  s4d3: "hot tub/jacuzzi",
  s4d4: "TV area / shared lounge",

}


export const roomDS = {
  header: headerDS,
  roomType: roomTypeDS,
  roomPrice: roomPriceDS,
  roomDetail: roomDetailDS
}