import ReservarLink from 'components/link/ReservarLink';
import React from 'react';
import { rutas } from 'router/rutas';
const wellcomebg1 = require('assets/images/intiResource/5.png');
const fondoService = require('assets/images/intiServicios/portada.png');

const sc1 = require('assets/images/intiServicios/instalaciones.png');
const sc2 = require('assets/images/intiServicios/actividades.png');
const sc3 = require('assets/images/intiServicios/voluntarios.png');

export const HeaderDataSource = {
    children: [
        {
            name: 'retiros',
            icon: 'flaticon-analysis',
            title: 'home.header.hospedaje',
            description: 'home.header.hospedajeDetalle',
            linklabel: 'more',
            href: rutas.apartamentos.href
        },
        {
            name: 'hospedaje',
            icon: 'flaticon-destination',
            title: 'home.header.evento',
            description: 'home.header.eventoDetalle',
            linklabel: 'more',
            href: rutas.retiros.href,
            style: { backgroundImage: "url(" + wellcomebg1 + ")", backgroundSize: 'cover' },

        },
        {
            name: 'comunidad',
            icon: 'flaticon-home-insurance',
            title: 'home.header.vinculacion',
            description: 'home.header.vinculacionDetalle',
            linklabel: 'more',
            href: rutas.comunidad.href
        },
    ],
};

export const AbautDataSource = {
    name: 'abaut',
    title: 'home.abaut.subtitle',
    subtitle1: 'home.abaut.title',
    subtitle2: '',
    description: 'home.abaut.detail',
    description2: 'home.abaut.detail2',
    description3: 'home.abaut.detail3',
};

export function DetalleCountHomeDataSource(t) {
    return {
        wrapper: { className: 'home-page-wrapper feature6-wrapper' },
        OverPack: { className: 'home-page feature6', playScale: 0.3 },
        Carousel: {
            className: 'feature6-content',
            dots: false,
            wrapper: { className: 'feature6-content-wrapper' },
            haveTitle: false,
            titleWrapper: {
                className: 'feature6-title-wrapper',
                barWrapper: {
                    className: 'feature6-title-bar-wrapper',
                    children: { className: 'feature6-title-bar' },
                },
                title: { className: 'feature6-title' },
            },
            children: [
                {
                    title: {
                        className: 'feature6-title-text',
                        children: (
                            <span>
                                <span>
                                    <p>Detalle</p>
                                </span>
                            </span>
                        ),
                    },
                    className: 'feature6-item',
                    name: 'block0',
                    children: [
                        {
                            md: 8,
                            xs: 24,
                            className: 'feature6-number-wrapper',
                            name: 'child0',
                            number: {
                                className: 'feature6-number',
                                unit: { className: 'feature6-unit', children: t('home.statistics.statistics1') },
                                toText: true,
                                children: '6000',
                            },
                            children: {
                                className: 'feature6-text kzxlhybpzvl-editor_css',
                                children: (
                                    <span>
                                        <p>
                                            {t('home.statistics.statisticsDetail1')}
                                        </p>
                                    </span>
                                ),
                            },
                        },
                        {
                            md: 8,
                            xs: 24,
                            className: 'feature6-number-wrapper',
                            name: 'child1',
                            number: {
                                className: 'feature6-number',
                                unit: { className: 'feature6-unit', children: t('home.statistics.statistics2') },
                                toText: true,
                                children: '200',
                            },
                            children: {
                                className: 'feature6-text kzxlla7vfl-editor_css',
                                children: (
                                    <span>
                                        <span>
                                            <p>
                                                {t('home.statistics.statisticsDetail2')}
                                            </p>
                                        </span>
                                    </span>
                                ),
                            },
                        },
                        {
                            md: 8,
                            xs: 24,
                            className: 'feature6-number-wrapper',
                            name: 'child2',
                            number: {
                                className: 'feature6-number',
                                unit: { className: 'feature6-unit', children: t('home.statistics.statistics3') },
                                toText: true,
                                children: '60',
                            },
                            children: {
                                className: 'feature6-text kzxllh6npa-editor_css',
                                children: (
                                    <span>
                                        <p>
                                            {t('home.statistics.statisticsDetail3')}
                                        </p>
                                    </span>
                                ),
                            },
                        },
                    ],
                },
            ],
        },
    };
}

export function cataServiciosDataSource(t) {
    return {

        name: 'service',
        background: fondoService,
        title: t('home.natureService.title'),
        subtitle: t('home.natureService.subtitle'),
        headerChildren: [
            {
                category: t('home.natureService.instalation'),
                image: sc1,
                href: rutas.apartamentos.href,
                subtitle: t('home.natureService.instalationDetail'),
            },
            {
                category: t('home.natureService.activities'),
                image: sc2,
                href: rutas.retiros.href,
                subtitle: t('home.natureService.activitiesDetail'),

            },
            {
                category: t('home.natureService.volunteers'),
                image: sc3,
                href: rutas.voluntarios.href,
                subtitle: t('home.natureService.volunteersDetail'),
            },
        ],
    }
};

export function ServiciosDataSource(t) {
    return {
        detailCategoy: [
            {
                eventKey: 'first',
                category: t('home.service.salon'),
                icon: 'flaticon-collaboration',
                body: {
                    title: t('home.service.salon'),
                    subtitle: 'Inti Kamari',
                    image: require('assets/images/intiSalas/salas.png'),
                    description: t('home.service.salonDetail'),
                }
            },
            {
                eventKey: 'second',
                category: t('home.service.room'),
                icon: 'flaticon-home-insurance',
                body: {
                    title: t('home.service.room'),
                    subtitle: 'Inti Kamari',
                    image: require('assets/images/intiSalas/habitaciones.png'),
                    description: t('home.service.roomDetail'),
                }
            },
            {
                eventKey: 'third',
                category: t('home.service.library'),
                icon: 'flaticon-notebook',
                body: {
                    title: t('home.service.library'),
                    subtitle: 'Inti Kamari',
                    image: require('assets/images/intiSalas/biblioteca.png'),
                    description: t('home.service.libraryDetail'),
                }
            },
            {
                eventKey: 'fourth',
                category: t('home.service.greenAreas'),
                icon: 'flaticon-worldwide',
                body: {
                    title: t('home.service.greenAreas'),
                    subtitle: 'Inti Kamari',
                    image: require('assets/images/intiSalas/verdes.png'),
                    description: t('home.service.greenAreasDetail'),
                }
            },
            {
                eventKey: 'fifth',
                category: t('home.service.activity'),
                icon: 'flaticon-life-insurance',
                body: {
                    title: t('home.service.activity'),
                    subtitle: 'Inti Kamari',
                    image: require('assets/images/intiSalas/actividades.png'),
                    description:
                        <>
                            {t('home.service.activityDetail1')}<br />
                            {t('home.service.activityDetail2')} <br />
                            {t('home.service.activityDetail3')}  <br />
                            {t('home.service.activityDetail4')} <br />
                            {t('home.service.activityDetail5')} <br />
                            {t('home.service.activityDetail6')}  <br />
                            {t('home.service.activityDetail7')} <br /><br />
                            {t('home.service.activityDetail8')}
                        </>,
                }
            },
            {
                eventKey: 'sixth',
                category: t('home.service.food'),
                icon: 'flaticon-star',
                body: {
                    title: t('home.service.food'),
                    subtitle: 'Inti Kamari',
                    image: require('assets/images/intiSalas/alimentos.png'),
                    description: t('home.service.foodDetail'),
                }
            },
        ],
    }
};

export const UbicacionDs = {
    title: 'home.maps.title',
    subtitle: 'home.maps.subtitle',
    body: 'home.maps.detail',

};

export const ActividadesHomeDataSource = {
    title: 'home.statistics.title',
    subtitle: 'home.statistics.subtitle',
    detailCategoy: [],
};

export function ServiceExtraTabDataSource(t) {
    return {
        title: t('home.additionalServices.title'),
        subtitle: 'Inti Kamari',
        image1: require('assets/images/intiServicios/comida1.jpg'),
        image2: require('assets/images/intiServicios/pet-friendly.jpg'),
        detailCategoy: [
            {
                eventKey: 'first',
                category: t('home.additionalServices.pet'),
                body: {
                    image: require('assets/images/intiResource/5.png'),
                    description:
                        <>
                            {t('home.additionalServices.petDetail1')}<br /><br />
                            {t('home.additionalServices.petDetail2')}
                        </>,
                }
            },
            {
                eventKey: 'second',
                category: t('home.additionalServices.food'),
                body: {
                    image: require('assets/images/intiResource/5.png'),
                    description:
                        <>
                            <>
                                {t('home.additionalServices.foodDetail1')} <br />
                                {t('home.additionalServices.foodDetail2')}  <br /><br />
                                {t('home.additionalServices.foodDetail3')}
                            </>
                        </>,
                }
            },
        ],
    }
};

export function GaleriaHomeDS(t) {
    return {
        title: '',
        subtitle: t('menu.subtitle.galeria'),
        images: [
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/1.jpg'),

            },
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/2.jpg'),

            },
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/3.jpg'),

            },
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/4.jpg'),

            },
            {
                name: t('menu.subtitle.galeria'),
                img: require('assets/images/intiGallery/home/5.jpg'),

            },
        ]

    }
}
